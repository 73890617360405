@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Inter:wght@100..900&display=swap');

:root {
    --bg-colog: rgb(17, 18, 21);
    --text-color: #AAAAAA;
    --text-size: 16px;
    --h-color: rgb(245, 245, 247);
    --link-color: #46A4F6;
    --badge-bg: rgba(255, 255, 255, .2);
    --bs-secondary-color: #717171;
    --text-font: "DM Mono", monospace;
    --title-font: "Inter-BlackItalic", "Inter", sans-serif;
}

body {
    margin: 0;
    font-family: var(--title-font);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--text-color);
    background-color: var(--bg-colog);
}

p, a {
    font-family: var(--text-font);
    font-size: var(--text-size);
    font-style: normal;
    font-weight: 400;
    line-height: 1.75em;
    margin: 0;
    color: var(--text-color);
    transition: all .2s ease;
}

a:hover {
    color: var(--link-color);
    text-decoration: underline;
    text-decoration-color: var(--link-color);
}

i {
    color: var(--text-color);
    height: 20px;
    width: 20px;
}

h1, h2, h3, h4, h5, h6 {
    color: var(--h-color);
    font-style: italic;
    font-weight: 900;
    margin: 0;
}

h1 {
    font-size: calc(var(--text-size) * 3);
}

h2 {
    font-size: calc(var(--text-size) * 2);
}

h3 {
    font-size: calc(var(--text-size) + 8);
    font-weight: 700;
    font-style: normal;
}

.container {
    padding: 76px var(--text-size) 100px;
    align-content: center;
    align-items: center;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: calc(var(--text-size) * 2);
    height: min-content;
    justify-content: center;
    max-width: 630px;
    overflow: visible;
    position: relative;
    width: 100%
}

.section {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex: none;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: var(--text-size);
    height: min-content;
    justify-content: center;
    overflow: visible;
    padding: 0;
    position: relative;
    transform: perspective(1200px);
    width: 100%
}

.section.section-content {
    gap: calc(var(--text-size) / 2);
    height: min-content;
    justify-content: flex-start;
    overflow: hidden;
    padding: var(--text-size) 0 0;
}

.section-ref {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: calc(var(--text-size) / 2);
    height: min-content;
    justify-content: center;
    overflow: hidden;
    padding: 0;
    position: relative;
    text-decoration: none;
}

.section-ref:hover {
    text-decoration-color: var(--text-color);
}

.link {
    color: var(--link-color);
    text-decoration: none;
}

.link:hover i {
    text-decoration-color: var(--text-color);
    text-decoration: underline;
}

.upper-button {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    height: min-content;
    justify-content: flex-end;
    overflow: hidden;
    padding: calc(var(--text-size) / 2);
    position: relative;
    width: 100%;
    border-bottom: 1px var(--badge-bg) solid;
}

.upper-button i {
    color: var(--badge-bg);
}

.project-button {
    align-content: flex-start;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: var(--text-size);
    height: min-content;
    justify-content: center;
    overflow: hidden;
    padding: var(--text-size);
    position: relative;
    text-decoration: none;
    width: 100%;
    will-change: transform;
    transition: all .2s ease;
    border-radius: calc(var(--text-size) / 2);
}

.project-button:hover {
    background-color: rgba(255, 255, 255, .05);
    text-decoration: none;
}

.project-button .link {
    color: var(--link-color);
    text-decoration: none;
}

.project-button:hover .link {
    text-decoration: underline;
    text-decoration-color: var(--link-color);
}

.project-button-header {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: none;
    gap: var(--text-size);
}

.badge {
    background-color: var(--badge-bg);
    font-size: 12px;
    font-weight: 400;
    line-height: 1em;
}

.contact-item {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: none;
    gap: var(--text-size);
    width: 100%;
    justify-content: space-between;
}

.contact-item .line {
    background-color: var(--badge-bg);
    opacity: 1;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex: 1 0 0;
    flex-wrap: nowrap;
    height: 1px;
    gap: var(--text-size);
}